import { setLoading } from "appdata/loader/loaderSlice";
import { setUser, updateMe } from "appdata/myself/myselfSlice";
import PropTypes from "prop-types";
import {
  ChangeEvent,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Popup from "reactjs-popup";
import { deepEqual } from "utils/func";

import SwitchUserImg from "assets/img/switch-user.webp";
import UserInformationImg from "assets/img/user-information.webp";

import { AppDispatch, RootState } from "appdata/store";
import { useNotification } from "contexts/notification-context/NotificationContext";
import { User } from "types/userTypes";
import styles from "./Popup.module.scss";

interface PopupContextProps {
  isSwitchUserPopupOpen: boolean;
  setIsSwitchUserPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isUserInfomationPopupOpen: boolean;
  setIsUserInfomationPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const PopupContext = createContext<PopupContextProps | undefined>(undefined);

export const PopupProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { notifyError } = useNotification();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const [customId, setCustomId] = useState("");
  const myselfRedux = useSelector((state: RootState) => state.myselfRedux);

  const [isSwitchUserPopupOpen, setIsSwitchUserPopupOpen] = useState(false);
  const [isUserInfomationPopupOpen, setIsUserInfomationPopupOpen] =
    useState(false);

  const [userInfo, setUserInfo] = useState<Partial<User> | null>();

  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    const isEqual = deepEqual(userInfo, myselfRedux.me);
    setIsChanged(!isEqual);
  }, [userInfo]);

  const handleInputChange = (
    e: ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    setUserInfo((prev) => ({
      ...prev,
      [name]: name === "age" ? Number(value) : value,
    }));
  };

  const handleSaveUserPublisherData = () => {
    if (!isChanged) return;

    dispatch(setLoading(true));
    dispatch(updateMe(userInfo!))
      .unwrap()
      .then((data) => {
        dispatch(setLoading(false));
        setIsUserInfomationPopupOpen(false);
      })
      .catch((error) => {
        notifyError();
      });
  };

  const handleSwitchingUser = async () => {
    if (customId === "" || customId.toUpperCase() === myselfRedux.me?.username)
      return;
    dispatch(setLoading(true));
    const loginData = {
      username: customId,
      type: "patient",
    };

    try {
      const response = await fetch(
        `${window._env_.REACT_APP_API_URL}/api/v1/auth/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(loginData),
        }
      );
      if (!response.ok) {
        throw new Error("Login failed");
      }

      const result = await response.json();

      dispatch(setUser(result.user));

      const { accessToken, refreshToken } = result;

      const authData = {
        accessToken,
        refreshToken,
      };

      sessionStorage.setItem("mi01_auth", JSON.stringify(authData));
      navigate("/dashboard");
      window.location.reload();
    } catch (error) {
      notifyError();
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    if (!myselfRedux.me) return;
    setUserInfo(myselfRedux.me);
  }, [myselfRedux]);

  const contextValue = useMemo(
    () => ({
      isSwitchUserPopupOpen,
      setIsSwitchUserPopupOpen,
      isUserInfomationPopupOpen,
      setIsUserInfomationPopupOpen,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSwitchUserPopupOpen, isUserInfomationPopupOpen]
  );

  return (
    <PopupContext.Provider value={contextValue}>
      {children}

      <Popup
        contentStyle={{ zIndex: 1001 }}
        open={isSwitchUserPopupOpen}
        modal
        onClose={() => {
          setIsSwitchUserPopupOpen(false);
        }}
        closeOnDocumentClick
      >
        <div className={styles.customSwitchUserPopup}>
          <button
            className={`${styles.close_btn} size_1`}
            onClick={() => {
              setIsSwitchUserPopupOpen(false);
            }}
          >
            &times;
          </button>

          <div className={styles.switch_user_popup_content}>
            <img alt="switch-user" src={SwitchUserImg}></img>
            <div className={styles.side}>
              <div className={`size_1 text_gradient ${styles.title}`}>
                {t("common_text.userSwitching")}
              </div>
              <p>{`"${t("header.changeCharacterMessage")}"`}</p>
              <p>{`"${t("header.discoverGameMessage")}"`}</p>

              <div className={styles.input_box}>
                <input
                  placeholder={t("common_text.enterThePatientCode")}
                  value={customId}
                  onChange={(e) => setCustomId(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSwitchingUser();
                    }
                  }}
                />
              </div>

              <button
                className={`${styles.switching_btn} button_gradient`}
                onClick={handleSwitchingUser}
              >
                {t("common_text.switching")}
              </button>
            </div>
          </div>
        </div>
      </Popup>

      <Popup
        contentStyle={{ zIndex: 1001 }}
        open={isUserInfomationPopupOpen}
        modal
        onClose={() => {
          setIsUserInfomationPopupOpen(false);
          setUserInfo(myselfRedux.me);
        }}
        closeOnDocumentClick
      >
        <div className={styles.customUserInformationPopup}>
          <button
            className={`${styles.close_btn} size_1`}
            onClick={() => {
              setIsUserInfomationPopupOpen(false);
            }}
          >
            &times;
          </button>

          <div className={styles.user_infor_popup_content}>
            <div className={styles.left_side}>
              <img alt="user-infomation" src={UserInformationImg}></img>
            </div>
            <div className={styles.side}>
              <div className={`size_1 text_gradient ${styles.title}`}>
                {t("common_text.userInformation")}
              </div>
              <p>{`"${t("header.personalInfoRequestMessage")}"`}</p>

              <div className={styles.input_row}>
                <input
                  type="text"
                  name="name"
                  value={userInfo?.name || ""}
                  onChange={handleInputChange}
                  placeholder={`${t("common_text.fullName")}`}
                />
              </div>
              <div className={styles.input_row}>
                <input
                  type="text"
                  name="phone"
                  value={userInfo?.phone || ""}
                  onChange={handleInputChange}
                  placeholder={`${t("common_text.phoneNumber")}`}
                />
              </div>
              <div className={styles.input_row}>
                <select
                  name="gender"
                  value={userInfo?.gender || ""}
                  onChange={handleInputChange}
                  // placeholder={`${t("common_text.gender")}`}
                >
                  <option value="male">{t("common_text.male")}</option>
                  <option value="female">{t("common_text.female")}</option>
                </select>
              </div>
              <div className={styles.input_row}>
                <input
                  type="number"
                  name="age"
                  value={userInfo?.age !== 0 ? userInfo?.age : ""}
                  onChange={handleInputChange}
                  placeholder={`${t("common_text.age")}`}
                  min={1}
                />
              </div>

              <button
                className={`${styles.submit_btn} ${
                  isChanged ? styles.active : styles.inactive
                } rounded button_gradient`}
                onClick={handleSaveUserPublisherData}
                disabled={!isChanged}
              >
                {t("button.save")}
              </button>
            </div>
          </div>
        </div>
      </Popup>
    </PopupContext.Provider>
  );
};

PopupProvider.propTypes = {
  children: PropTypes.any,
};

export const usePopup = () => {
  const context = useContext(PopupContext);
  if (!context) {
    throw new Error("usePopup must be used within a PopupProvider");
  }
  return context;
};
