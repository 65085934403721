import Popover from "components/Popover/Popover";
import { usePopup } from "contexts/popup-context/PopupContext";
import { LANGUAGE_CODES, LANGUAGE_NAMES, LanguageCode } from "helps/Languages";
import { useCallback, useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import ArrowDownIcon from "assets/icons/arrow-down.svg";
import CameraConnectIcon from "assets/icons/camera-connect.svg";
import CameraIcon from "assets/icons/camera.svg";
import CheckIcon from "assets/icons/check.svg";
import LanguageIcon from "assets/icons/language.svg";
import LogoutIcon from "assets/icons/logout.svg";
import SwitchUserIcon from "assets/icons/switch-user.svg";
import UserInformationIcon from "assets/icons/user-information.svg";
import UserLogoIcon from "assets/icons/user.svg";
import MiraboImg from "assets/img/mirabo-logo.webp";

import { HAND_CONNECTIONS } from "@mediapipe/hands";
import { FilesetResolver, HandLandmarker } from "@mediapipe/tasks-vision";
import { updateMe } from "appdata/myself/myselfSlice";
import { AppDispatch, RootState } from "appdata/store";
import { useNotification } from "contexts/notification-context/NotificationContext";
import { useDispatch, useSelector } from "react-redux";
import Popup from "reactjs-popup";
import styles from "./Header.module.scss";

type AnchorElement = HTMLElement | null;

function Header() {
  const { openNotification } = useNotification();
  const { i18n, t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { setIsSwitchUserPopupOpen, setIsUserInfomationPopupOpen } = usePopup();

  const me = useSelector((state: RootState) => state.myselfRedux.me);

  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [isSettingCameraPopupOpen, setIsSettingCameraPopupOpen] =
    useState(false);

  const [stream, setStream] = useState<MediaStream | null>(null);
  const [currentCameraId, setCurrentCameraId] = useState<string | null>(null);
  const [availableCameras, setAvailableCameras] = useState<MediaDeviceInfo[]>(
    []
  );
  const [isDropdownLanguageOpen, setIsDropdownLanguageOpen] = useState(false);

  const [anchorElRegion, setAnchorElRegion] = useState<AnchorElement>(null);
  const [anchorProfile, setAnchorProfile] = useState<AnchorElement>(null);

  const [handLandmarker, setHandLandmarker] = useState<HandLandmarker | null>(
    null
  );
  const [isVideoLoading, setIsVideoLoading] = useState(true);
  const [updateFlag, setUpdateFlag] = useState(0);
  const flipCameraRef = useRef<boolean | null>(null);

  const handleRegionClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorElRegion(e.currentTarget);
      setIsDropdownLanguageOpen(true);
    },
    []
  );

  const handleProfileClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorProfile(e.currentTarget);
  };

  const handleCloseRegion = useCallback(() => {
    setAnchorElRegion(null);
    setIsDropdownLanguageOpen(false);
  }, []);

  const handleCloseProfile = () => {
    setAnchorProfile(null);
  };

  const onChangeLanguage = useCallback(
    (value: LanguageCode) => {
      dispatch(
        updateMe({
          language: value === "vi" ? "vi-VN" : value,
        })
      );
      i18n.changeLanguage(value);
      localStorage.setItem("mi01_la", value);
    },
    [dispatch, i18n]
  );

  const openRegion = Boolean(anchorElRegion);
  const idRegion = openRegion ? "region-popover" : undefined;

  const openProfile = Boolean(anchorProfile);
  const idProfile = openProfile ? "profile-popover" : undefined;

  const handleCameraChange = async (deviceId: string) => {
    setCurrentCameraId(deviceId);
    localStorage.setItem("defaultCameraId", deviceId);
  };

  const handleFlipCamera = () => {
    setIsVideoLoading(true);

    navigator.mediaDevices.enumerateDevices().then((devices) => {
      const videoDevices = devices.filter(
        (device) => device.kind === "videoinput"
      );

      if (videoDevices.length === 0) {
        openNotification(
          "error",
          "Error!",
          "No camera detected! Please connect a camera to play."
        );

        return;
      } else {
        flipCameraRef.current = !flipCameraRef.current;
        localStorage.setItem(
          `cameraFlipState_${currentCameraId}`,
          flipCameraRef.current ? "true" : "false"
        );
        setUpdateFlag((prev) => prev + 1);
      }
    });
  };

  const predictWebcam = async () => {
    setIsVideoLoading(false);
    if (!canvasRef.current || !videoRef.current) return;
    const canvasElement = canvasRef.current;

    const canvasCtx = canvasElement.getContext("2d");
    if (!canvasCtx) return;

    const video = videoRef.current;
    let results = undefined;
    if (video.readyState < 2) {
      console.log("Video chưa sẵn sàng");
      return;
    }

    canvasElement.width = video.getBoundingClientRect().width;
    canvasElement.height = video.getBoundingClientRect().height;

    const flipCanvas = document.createElement("canvas");
    flipCanvas.width = video.videoWidth;
    flipCanvas.height = video.videoHeight;
    const flipCtx = flipCanvas.getContext("2d");

    let lastVideoTime = -1;
    if (lastVideoTime !== video.currentTime && flipCtx) {
      lastVideoTime = video.currentTime;

      flipCtx.save();

      if (flipCameraRef.current) {
        flipCtx.scale(-1, 1); // Lật ngang
        flipCtx.translate(-video.videoWidth, 0); // Điều chỉnh vị trí
      }
      flipCtx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
      flipCtx.restore();

      // results = handLandmarker!.detectForVideo(flipCanvas, performance.now());
      if (handLandmarker) {
        try {
          results = handLandmarker.detectForVideo(
            flipCanvas,
            performance.now()
          );
        } catch (error) {
          console.error("Error during hand landmark detection:", error);
          return;
        }
      }
    }

    if (results && results.landmarks) {
      for (let i = 0; i < results.landmarks.length; i++) {
        const landmarks = results.landmarks[i];
        const handedness = results.handedness[i][0].categoryName;

        // Vẽ các đường nối
        for (const [startIdx, endIdx] of HAND_CONNECTIONS) {
          const startPoint = landmarks[startIdx];
          const endPoint = landmarks[endIdx];

          canvasCtx.beginPath();
          canvasCtx.moveTo(
            (false ? startPoint.x : 1 - startPoint.x) * canvasElement.width,
            startPoint.y * canvasElement.height
          );
          canvasCtx.lineTo(
            (false ? endPoint.x : 1 - endPoint.x) * canvasElement.width,
            endPoint.y * canvasElement.height
          );
          canvasCtx.strokeStyle = "#00FF00";
          canvasCtx.lineWidth = 5;
          canvasCtx.stroke();
        }

        // Vẽ các điểm
        for (const point of landmarks) {
          canvasCtx.beginPath();
          canvasCtx.arc(
            (false ? point.x : 1 - point.x) * canvasElement.width,
            point.y * canvasElement.height,
            5,
            0,
            2 * Math.PI
          );
          canvasCtx.fillStyle = "red";
          canvasCtx.fill();
        }

        // Tính trung tâm lòng bàn tay
        const palmCenterX =
          (landmarks[0].x +
            landmarks[5].x +
            landmarks[9].x +
            landmarks[13].x +
            landmarks[17].x) /
          5;
        const palmCenterY =
          (landmarks[0].y +
            landmarks[5].y +
            landmarks[9].y +
            landmarks[13].y +
            landmarks[17].y) /
          5;

        const label =
          handedness === "Left" ? t("header.leftHand") : t("header.rightHand");
        const color = handedness === "Left" ? "blue" : "green"; // Màu khác nhau cho từng tay

        // Vẽ hình tròn lớn tại trung tâm lòng bàn tay
        canvasCtx.beginPath();
        canvasCtx.arc(
          (false ? palmCenterX : 1 - palmCenterX) * canvasElement.width,
          palmCenterY * canvasElement.height,
          50, // Kích thước lớn hơn cho vòng tròn
          0,
          2 * Math.PI
        );
        canvasCtx.fillStyle = color;
        canvasCtx.fill();

        // Vẽ text ở giữa hình tròn

        // Đo độ rộng và chiều cao của text
        canvasCtx.font = "1.2vw Arial";
        const textWidth = canvasCtx.measureText(label).width;

        // Tính vị trí để căn chỉnh text nằm giữa vòng tròn
        const textX =
          (false ? palmCenterX : 1 - palmCenterX) * canvasElement.width;
        const textY = palmCenterY * canvasElement.height;

        // Đảm bảo rằng văn bản không vượt ra ngoài vòng tròn
        const maxTextWidth = 50 * 2; // Bán kính vòng tròn * 2 (để lấy đường kính)
        if (textWidth > maxTextWidth) {
          // Nếu văn bản quá rộng, điều chỉnh kích thước
          canvasCtx.font = "1vw Arial"; // Giảm font-size nếu cần
        }

        canvasCtx.fillStyle = "white";
        canvasCtx.textAlign = "center";
        canvasCtx.textBaseline = "middle";
        canvasCtx.fillText(label, textX, textY);
      }
    }

    requestAnimationFrame(predictWebcam);
  };

  useEffect(() => {
    if (currentCameraId) {
      const savedFlipState = localStorage.getItem(
        `cameraFlipState_${currentCameraId}`
      );
      const isCameraMirrored = savedFlipState === "true";
      flipCameraRef.current = isCameraMirrored;
      setUpdateFlag((prev) => prev + 1);
    }
  }, [currentCameraId]);

  useEffect(() => {
    if (!currentCameraId || !isSettingCameraPopupOpen) return;
    const constraints = {
      video: {
        deviceId: currentCameraId ? { exact: currentCameraId } : undefined,
      },
    };

    let newStream: MediaStream | null = null;
    const controller = new AbortController();
    const signal = controller.signal;

    if (videoRef.current) {
      videoRef.current.removeEventListener("loadeddata", predictWebcam);
    }
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((_stream) => {
        // Kiểm tra xem tín hiệu đã bị hủy chưa
        if (signal.aborted) {
          _stream.getTracks().forEach((track) => track.stop());
          return;
        }
        newStream = _stream;
        if (videoRef.current) {
          videoRef.current.srcObject = newStream;
          setStream(newStream);
          videoRef.current.addEventListener("loadeddata", predictWebcam);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          console.log("Camera stream request aborted");
        } else {
          console.error("Error getting media stream: ", error);
        }
      });

    return () => {
      controller.abort();
      if (newStream) {
        newStream.getTracks().forEach((track) => track.stop());
      }
      if (videoRef.current) {
        videoRef.current.removeEventListener("loadeddata", predictWebcam);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCameraId, isSettingCameraPopupOpen, handLandmarker]);

  useEffect(() => {
    const initializeHandLandmarker = async () => {
      const vision = await FilesetResolver.forVisionTasks(
        "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.17/wasm"
      );
      const handLandmarker = await HandLandmarker.createFromOptions(vision, {
        baseOptions: {
          delegate: "GPU",
          modelAssetPath:
            "https://storage.googleapis.com/mediapipe-models/hand_landmarker/hand_landmarker/float16/1/hand_landmarker.task",
        },
        numHands: 2,
        runningMode: "VIDEO",
      });
      setHandLandmarker(handLandmarker);
    };

    const getAvailableCameras = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(
          (device) => device.kind === "videoinput"
        );
        setAvailableCameras(videoDevices);
        let defaultCameraId = localStorage.getItem("defaultCameraId");

        if (videoDevices.length > 0 && !currentCameraId) {
          if (!defaultCameraId) {
            defaultCameraId = videoDevices[0].deviceId;
            localStorage.setItem("defaultCameraId", defaultCameraId);
          }

          setCurrentCameraId(
            defaultCameraId &&
              videoDevices.some((device) => device.deviceId === defaultCameraId)
              ? defaultCameraId
              : videoDevices[0].deviceId
          );
        }
      } catch (err) {
        console.error("Error fetching devices", err);
      }
    };

    initializeHandLandmarker();
    getAvailableCameras();

    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.header_wrapper}>
      <div className={styles.left}>
        <img
          src={MiraboImg}
          alt="Mirabo"
          style={{ cursor: "pointer" }}
          onClick={() => {
            window.location.href = "/dashboard";
          }}
        />
      </div>

      <div className={styles.right}>
        {location.pathname !== "/gameplay" && (
          <>
            <button
              className={`${styles.language_btn} rounded`}
              onClick={() => setIsSettingCameraPopupOpen(true)}
            >
              <img
                style={{
                  objectFit: "cover",
                }}
                src={CameraIcon}
                alt="Camera icon"
              />
              <span className={`size_small`} style={{ textWrap: "nowrap" }}>
                {t("header.cameraSettings")}
              </span>
            </button>

            <Popup
              contentStyle={{ zIndex: 1001 }}
              open={isSettingCameraPopupOpen}
              modal
              onClose={() => {
                setIsSettingCameraPopupOpen(false);
                setIsVideoLoading(true);
              }}
              closeOnDocumentClick
            >
              <div className={styles.custom_setting_camera_popup}>
                <button
                  className={`${styles.close_btn} size_1`}
                  onClick={() => {
                    setIsSettingCameraPopupOpen(false);
                  }}
                >
                  &times;
                </button>

                <div className={styles.popup_title}>
                  {t("header.cameraSettings")}
                </div>

                <div className={styles.body}>
                  <div className={styles.left}>
                    <div className={styles.camera_title}>
                      {t("header.selectCameraTitle")}
                    </div>
                    {availableCameras.map((camera, index) => (
                      <button
                        key={camera.deviceId}
                        className={`${styles.camera_option} ${
                          currentCameraId === camera.deviceId
                            ? styles.selected
                            : ""
                        }`}
                        onClick={() => handleCameraChange(camera.deviceId)}
                      >
                        <span>{`Camera ${String.fromCharCode(
                          65 + index
                        )}`}</span>
                        {currentCameraId === camera.deviceId && (
                          <img src={CheckIcon} alt="icon" />
                        )}
                      </button>
                    ))}
                    <div className={styles.instructions}>
                      <div className={styles.instruction_title}>
                        {t("header.instructionsTitle")}
                      </div>
                      <ol>
                        <li>
                          <Trans
                            i18nKey="header.instructions.step1"
                            components={{ strong: <strong /> }}
                          />
                          <ul>
                            <li>
                              <Trans
                                i18nKey="header.instructions.step1_1"
                                components={{ strong: <strong /> }}
                              />
                              <ul>
                                <li>
                                  <Trans
                                    i18nKey="header.instructions.step1_2"
                                    components={{ strong: <strong /> }}
                                  />
                                </li>
                              </ul>
                            </li>
                            <li>
                              {t("header.instructions.step1_3")}
                              <ul>
                                <li>
                                  <Trans
                                    i18nKey="header.instructions.step1_4"
                                    components={{ strong: <strong /> }}
                                  />
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                        <li>
                          {t("header.instructions.step2")}
                          <ul>
                            <li>
                              <Trans
                                i18nKey="header.instructions.step2_1"
                                components={{ strong: <strong /> }}
                              />
                            </li>
                          </ul>
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div className={styles.right}>
                    <div className={styles.camera_container}>
                      {isVideoLoading && (
                        <div className={styles.loadingOverlay}>
                          <img
                            style={{
                              objectFit: "cover",
                            }}
                            src={CameraConnectIcon}
                            alt="Camera Connect icon"
                          />{" "}
                          <span className={styles.loadingText}>Loading...</span>
                        </div>
                      )}
                      <video
                        ref={videoRef}
                        autoPlay
                        style={{
                          height: "100%",
                          transform: flipCameraRef.current
                            ? "rotateY(0deg)"
                            : "rotateY(180deg)",
                        }}
                        playsInline
                        muted
                      />
                      <canvas
                        ref={canvasRef}
                        className={styles.overlayCanvas}
                      />
                    </div>

                    <button
                      onClick={handleFlipCamera}
                      className={styles.flip_btn}
                    >
                      {localStorage.getItem(
                        `cameraFlipState_${currentCameraId}`
                      ) === "true"
                        ? t("header.resetCamera")
                        : t("header.flipCamera")}
                    </button>
                  </div>
                </div>
              </div>
            </Popup>

            <button
              className={`${styles.language_btn} rounded`}
              onClick={handleRegionClick}
            >
              <img
                style={{
                  objectFit: "cover",
                }}
                src={LanguageIcon}
                alt="Globe icon"
              />
              <span className={`size_small`} style={{ textWrap: "nowrap" }}>
                {
                  LANGUAGE_NAMES[
                    (localStorage.getItem("mi01_la") as LanguageCode) ?? "ja"
                  ]
                }
              </span>
              <img src={ArrowDownIcon} alt="t" />
            </button>

            <Popover
              id={idRegion}
              open={openRegion}
              anchorEl={anchorElRegion}
              onClose={handleCloseRegion}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <div
                className={`${styles.language_options} ${
                  isDropdownLanguageOpen ? styles.open : styles.closed
                }`}
              >
                {Object.entries(LANGUAGE_CODES).map(([key, value]) => (
                  <button
                    key={key}
                    className={`${styles.language_option} ${
                      i18n.language === value ? styles.selected : ""
                    }`}
                    onClick={() => onChangeLanguage(value as LanguageCode)}
                  >
                    <span>{LANGUAGE_NAMES[value as LanguageCode]}</span>
                    {i18n.language === value && (
                      <img src={CheckIcon} alt="icon" />
                    )}
                  </button>
                ))}
              </div>
            </Popover>

            <div className={styles.line}></div>
          </>
        )}

        <button
          className={`${styles.switch_user_btn}`}
          onClick={() => {
            setIsSwitchUserPopupOpen(true);
          }}
        >
          <img
            style={{
              objectFit: "cover",
              maxWidth: "100%",
            }}
            src={SwitchUserIcon}
            alt="Avatar"
          />
        </button>

        <div className={styles.line}></div>

        <button
          className={`${styles.profile_btn} rounded`}
          onClick={handleProfileClick}
        >
          <img
            style={{
              objectFit: "cover",
            }}
            src={UserLogoIcon}
            alt="Avatar"
          />
          <span className={`size_small`} style={{ textWrap: "nowrap" }}>
            {`ID: ${me?.username ?? ""}`}
          </span>
          <img src={ArrowDownIcon} alt="t" />
        </button>

        <Popover
          id={idProfile}
          open={openProfile}
          anchorEl={anchorProfile}
          onClose={handleCloseProfile}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <div className={styles.profile_popover}>
            <span>{`${t("header.hello")}, ${me?.username ?? ""}!`}</span>

            <button
              className={styles.user_information_btn}
              onClick={() => {
                handleCloseProfile();
                setIsUserInfomationPopupOpen(true);
              }}
            >
              <img
                style={{
                  objectFit: "cover",
                }}
                src={UserInformationIcon}
                alt="User information icon"
              />
              <span>{t("header.userInformation")}</span>
            </button>
            <div className={styles.line}></div>
            <button
              className={styles.logout_btn}
              onClick={() => {
                sessionStorage.removeItem("mi01_auth");
                navigate("/login");
              }}
            >
              <img
                style={{
                  objectFit: "cover",
                }}
                src={LogoutIcon}
                alt="Logout icon"
              />
              <span>{t("header.logout")}</span>
            </button>
          </div>
        </Popover>
      </div>
    </div>
  );
}

export default Header;
