import { App as AntdApp } from "antd";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import { setupAxios } from "utils/api";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import i18n from "./translation/i18n";

import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
setupAxios();
root.render(
  <I18nextProvider i18n={i18n}>
    <BrowserRouter>
      <AntdApp
        style={{
          fontFamily: "noto_sans_jp",
        }}
      >
        <App />
      </AntdApp>
    </BrowserRouter>
  </I18nextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
